import { CodeSnippet, singleColTextArr, MultiImageRow } from '../../../Utils/ComponentFactories';
import step1 from './1.png';
import step2 from './2.png';
import step3 from './3.png';
import step4 from './4.png';
import step5 from './5.png';
import step6 from './6.png';
import step7 from './7.png';
import step8 from './8.png';
import step9 from './9.png';
import step10 from './10.png';
import step11 from './11.png';
import step12 from './12.png';
import step13 from './13.png';
import step14 from './14.png';
import step15 from './15.png';
import step16 from './16.png';
import step17 from './17.png';
import step18 from './18.png';
import step19 from './19.png';
import step20 from './20.png';

let codeSnippet1 = `
  No code for this section
  `;
let codeSnippet2 = `
  python
  `;

export const InstallIde = {
  details: {
    name: 'Install IDE'
  },
  chapters: [
    {
      chapterTitle: 'Step 1',
      chapterId: 1,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Search “Pycharm” in google and click on the JetBrains link “https://www.jetbrains.com/pycharm/”`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step1, alt: 'Step 1' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 2',
      chapterId: 2,
      chapterContent: [
        singleColTextArr({
          textArr: [` Click the “Download” button on the website `]
        }),
        MultiImageRow(
          {
            images: [{ src: step2, alt: 'Step 2' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 3',
      chapterId: 3,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Scroll down the page and click the “Download” button for the PyCharm community version`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step3, alt: 'Step 3' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet2),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 4',
      chapterId: 4,
      chapterContent: [
        singleColTextArr({
          textArr: [`Once the download is complete, click on the .exe file`]
        }),
        MultiImageRow(
          {
            images: [{ src: step4, alt: 'Step 4' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 5',
      chapterId: 5,
      chapterContent: [
        singleColTextArr({
          textArr: [`You will see an install window open, click on “Next”`]
        }),
        MultiImageRow(
          {
            images: [{ src: step5, alt: 'Step 5' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 6',
      chapterId: 6,
      chapterContent: [
        singleColTextArr({
          textArr: [` Click “Next” again`]
        }),
        MultiImageRow(
          {
            images: [{ src: step6, alt: 'Step 6' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 7',
      chapterId: 7,
      chapterContent: [
        singleColTextArr({
          textArr: [`Check all the boxes as shown in the below image and click “Next”`]
        }),
        MultiImageRow(
          {
            images: [{ src: step7, alt: 'Step 7' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 8',
      chapterId: 8,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Now click “Install” and wait for the installation to complete, this will take a few minutes to complete.`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step8, alt: 'Step 8' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 9',
      chapterId: 9,
      chapterContent: [
        singleColTextArr({
          textArr: [`Select “I want to manually reboot later” and click the “Finish” button`]
        }),
        MultiImageRow(
          {
            images: [{ src: step9, alt: 'Step 9' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 10',
      chapterId: 10,
      chapterContent: [
        singleColTextArr({
          textArr: [` Now search for “PyCharm” in windows search and click on “Open”`]
        }),
        MultiImageRow(
          {
            images: [{ src: step10, alt: 'Step 10' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 11',
      chapterId: 11,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Once PyCharm opens check “I confirm” and click on “Continue” button as shown below`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step11, alt: 'Step 11' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 12',
      chapterId: 12,
      chapterContent: [
        singleColTextArr({
          textArr: [`Now click on “Don't send”`]
        }),
        MultiImageRow(
          {
            images: [{ src: step12, alt: 'Step 12' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 13',
      chapterId: 13,
      chapterContent: [
        singleColTextArr({
          textArr: [`Once the main PyCharms window opens, click on “New Project”`]
        }),
        MultiImageRow(
          {
            images: [{ src: step13, alt: 'Step 13' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 14',
      chapterId: 14,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Check “Create a welcome script” and rename the project “helloWorld” and click button “Create” as shown below`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step14, alt: 'Step 14' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 15',
      chapterId: 15,
      chapterContent: [
        singleColTextArr({
          textArr: [`Once the project window is open click on “main.py” to open it `]
        }),
        MultiImageRow(
          {
            images: [{ src: step15, alt: 'Step 15' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 16',
      chapterId: 16,
      chapterContent: [
        singleColTextArr({
          textArr: [`Clear all the text present in the file`]
        }),
        MultiImageRow(
          {
            images: [{ src: step16, alt: 'Step 16' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 17',
      chapterId: 17,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `We are now going to write your first program in python and run it. We will try and print out “Hello World” in the console. To achieve this copy the code present in the code section of our app and paste it onto the file`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step17, alt: 'Step 17' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 18',
      chapterId: 18,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Now to run your code, click on the green play button at the top right of PyCharm as shown below`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step18, alt: 'Step 18' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Step 19',
      chapterId: 19,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `If no error are present in the code, you will see the output printed in the console as hilighted below `
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step19, alt: 'Step 19' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    },
    {
      chapterTitle: 'Conclusion',
      chapterId: 20,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `You can repeat steps 17, 18 & 19 and just replace the text “Hello world” with different text. For example “Hello Steve” to see different outputs printed in your console`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step20, alt: 'Step 20' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1027185365
    }
  ]
};
