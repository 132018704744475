import React from 'react';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import PropTypes from 'prop-types';

const Login = ({
  onUsernameChange,
  usernameValue,
  onPasswordChange,
  passwordValue,
  onSubmit,
  signInLoading,
  signInError,
  onClickRegister,
  isFormValid
}) => {
  return (
    <>
      <div className="ace-loginPage__loginInputContainer">
        <Input
          id="Email"
          type="text"
          placeholder="What is their email?"
          // className="tmx-createAccessProfile__input"
          onChange={onUsernameChange}
          value={usernameValue}
          darkBorder
          label="User name"
        />
        <Input
          id="Email"
          type="password"
          placeholder="What is their email?"
          // className="tmx-createAccessProfile__input"
          onChange={onPasswordChange}
          value={passwordValue}
          darkBorder
          label="Password"
        />
      </div>
      <div className="ace-loginPage__loginButtonContainer">
        <Button
          buttonType="blue"
          className="ace-loginPage__loginButton"
          type="button"
          handleClick={onSubmit}
          disabled={!isFormValid() || signInLoading}
          spinner={signInLoading}>
          Login
        </Button>
      </div>
      <div className="ace-loginPage__errorMessage">
        {signInError ? 'Incorrect username or password' : ''}
      </div>
      <div className="ace-loginPage__signUpText">
        Don’t have an Account ?{' '}
        <span
          onClick={onClickRegister}
          className="ace-loginPage__signUpText ace-loginPage__signUpText--link">
          Register
        </span>
      </div>
    </>
  );
};

Login.propTypes = {
  onUsernameChange: PropTypes.func.isRequired,
  usernameValue: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  passwordValue: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  signInLoading: PropTypes.bool.isRequired,
  signInError: PropTypes.bool.isRequired,
  onClickRegister: PropTypes.func,
  isFormValid: PropTypes.func
};

export default Login;
