import React from 'react';
import { useNavigate } from 'react-router-dom';

import './ClassPage.scss';

const ClassPage = ({ navigateTo }) => {
  const navigate = useNavigate();

  function onNavigate() {
    if (navigateTo === 'practise') {
      navigate('/practise-textbook');
    } else {
      navigate('/textbook');
    }
  }

  return (
    <>
      <section className="ace-ClassPage">
        <div className="ace-ClassPage__classContainer">
          <div>Class:</div>
          <div className="ace-ClassPage__horizontalLine" />
          <div onClick={onNavigate} className="ace-ClassPage__classItem">
            X
          </div>
        </div>
      </section>
    </>
  );
};

ClassPage.propTypes = {};

export default ClassPage;
