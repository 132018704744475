import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './ComponentFactories.scss';
import classNames from 'classnames';

const TwoColTextImage = ({ alt, text, image }) => (
  <div className="ace-componentFactory__standardContentContainer">
    <div className="ace-componentFactory__standardTextContainer">{text}</div>
    <div className="ace-componentFactory__standardImageContainer">
      <Zoom>
        <img src={image} className="ace-componentFactory__standardImage" alt={alt} loading="lazy" />
      </Zoom>
    </div>
  </div>
);

const TwoColTextImageWrap = ({ alt, textArr, image }) => (
  <div>
    <div className="ace-componentFactory__standardImageContainer--float">
      <Zoom>
        <img src={image} className="ace-componentFactory__standardImage" alt={alt} loading="lazy" />
      </Zoom>
    </div>
    {/* <div className="ace-componentFactory__standardContentContainerCol"> */}
      {textArr &&
        textArr.map((text) => (
          <div className="ace-componentFactory__standardTextContainer--marginBottom">{text}</div>
        ))}
    {/* </div> */}
  </div>
);

const SingleColTextWithBullets = ({ text, bullets }) => (
  <div className="ace-componentFactory__standardQuestionAnswerContainer">
    <div className="ace-componentFactory__standardTextContainer">{text}</div>
    <div className="ace-componentFactory__standardTextContainer">
      <ul className="ace-componentFactory__bulletContainer">
        {bullets.map((bullet) => (
          <li>{bullet}</li>
        ))}
      </ul>
    </div>
  </div>
);

const singleColTextArr = ({ textArr }) => (
  <div className="ace-componentFactory__standardContentContainerCol">
    {textArr &&
      textArr.map((text) => (
        <div className="ace-componentFactory__standardTextContainer">{text}</div>
      ))}
  </div>
);

const MultiImageRow = ({ images }, isWide = false, showLabel = true) => (
  <div className="ace-componentFactory__centerContentContainer">
    {images.map((image, index) => (
      <div
        key={index}
        className={classNames('ace-componentFactory__standardImageContainer', {
          'ace-componentFactory__standardImageContainer--wide': isWide
        })}>
        <Zoom>
          <img
            src={image.src}
            className="ace-componentFactory__standardImage"
            alt={image.alt}
            loading="lazy"
          />
        </Zoom>
        {showLabel && <p className="ace-componentFactory__imageLabel">{image.alt}</p>}
      </div>
    ))}
  </div>
);

const questionAnswerBlock = ({ question, textArr }) => (
  <div className="ace-componentFactory__standardQuestionAnswerContainer">
    <div className="ace-componentFactory__standardTextContainer--bold">{question}</div>
    {textArr &&
      textArr.map((text) => (
        <div className="ace-componentFactory__standardTextContainer">{text}</div>
      ))}
  </div>
);

const quote = ({ text, author }) => (
  <div className="ace-componentFactory__standardQuoteContainer">
    <div className="ace-componentFactory__standardQuoteText">{text}</div>
    <div className="ace-componentFactory__standardQuoteAuthor">- {author}</div>
  </div>
);

const CodeSnippet = (code) => {
  function handleCopy() {
    navigator.clipboard.writeText(code);
  }

  return (
    <>
      <pre className="ace-componentFactory__codeSnippet">
        <code>{code.trim()}</code>
      </pre>
      <div onClick={handleCopy} className="ace-componentFactory__copyButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-copy">
          <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
          <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
        </svg>
      </div>
    </>
  );
};

const Exercise = ({ problem }) => {
  return (
    <div className="ace-componentFactory__standardQuestionAnswerContainer">
      <div className="ace-componentFactory__standardTextContainer">Exercise:</div>
      <div className="ace-componentFactory__standardTextContainer">{problem}</div>
    </div>
  );
};

export {
  TwoColTextImage,
  TwoColTextImageWrap,
  SingleColTextWithBullets,
  singleColTextArr,
  MultiImageRow,
  questionAnswerBlock,
  quote,
  CodeSnippet,
  Exercise
};
