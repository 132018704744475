import React from 'react';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import PropTypes from 'prop-types';

const SignUp = ({
  onUsernameChange,
  usernameValue,
  onPasswordChange,
  passwordValue,
  onConfirmPasswordChange,
  confirmPasswordValue,
  onSubmit,
  signInLoading,
  signInError,
  onClickLogin,
  isFormValid
}) => {
  return (
    <>
      <div className="ace-loginPage__signUpInputContainer">
        <Input
          id="Email"
          type="text"
          placeholder="Enter your email"
          // className="tmx-createAccessProfile__input"
          onChange={onUsernameChange}
          value={usernameValue}
          darkBorder
          label="User name"
        />
        <Input
          id="Email"
          type="password"
          placeholder="Enter your password"
          // className="tmx-createAccessProfile__input"
          onChange={onPasswordChange}
          value={passwordValue}
          darkBorder
          label="Password"
        />
        <Input
          id="Email"
          type="password"
          placeholder="Confirm your password"
          // className="tmx-createAccessProfile__input"
          onChange={onConfirmPasswordChange}
          value={confirmPasswordValue}
          darkBorder
          label="Confirm Password"
        />
      </div>
      <div className="ace-loginPage__signUpButtonContainer">
        <Button
          buttonType="blue"
          className="ace-loginPage__loginButton"
          type="button"
          handleClick={onSubmit}
          disabled={!isFormValid() || signInLoading}
          spinner={signInLoading}>
          Register
        </Button>
      </div>
      <div className="ace-loginPage__errorMessage">
        {signInError ? 'Incorrect username or password' : ''}
      </div>
      <div className="ace-loginPage__errorMessage">
        {confirmPasswordValue !== passwordValue ? "Passwords don't match" : ''}
      </div>
      <div className="ace-loginPage__loginText">
        Already have an account?{' '}
        <span
          onClick={onClickLogin}
          className="ace-loginPage__loginText ace-loginPage__loginText--link">
          Login
        </span>
      </div>
    </>
  );
};

SignUp.propTypes = {
  onUsernameChange: PropTypes.func.isRequired,
  usernameValue: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  passwordValue: PropTypes.string.isRequired,
  onConfirmPasswordChange: PropTypes.func.isRequired,
  confirmPasswordValue: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  signInLoading: PropTypes.bool.isRequired,
  signInError: PropTypes.bool.isRequired,
  onClickLogin: PropTypes.func,
  isFormValid: PropTypes.func
};

export default SignUp;
