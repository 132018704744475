import React from 'react';
import { useNavigate } from 'react-router-dom';

import './ChapterPage.scss';

const chapterArr = [
  {
    id: 1,
    name: 'Computer Vision'
  },
  {
    id: 2,
    name: 'Data Structures'
  }
];

const ChapterPageLearn = () => {
  const navigate = useNavigate();

  function onNavigate(chapterId) {
    navigate(`/theoryContentDetail/${chapterId}`);
  }

  return (
    <>
      <section className="ace-chapterPage">
        <div className="ace-chapterPage__chapterContainer">
          <div>Chapters</div>
          <div className="ace-chapterPage__horizontalLine" />

          {chapterArr &&
            chapterArr.map((chapter) => {
              return (
                <div
                  key={chapter?.id}
                  onClick={() => onNavigate(chapter?.id)}
                  className="ace-chapterPage__chapterBox">
                  <p>
                    Chapter {chapter?.id} - {chapter?.name}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="ace-chapterPage__bookIcon">
                    <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
                  </svg>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
};

ChapterPageLearn.propTypes = {};

export default ChapterPageLearn;
