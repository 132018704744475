import React from 'react';
import { useParams } from 'react-router-dom';
import PractisePage from '../PractisePage/PractisePage';
import { HowToInstallPython } from '../../ChapterModules/Practise/HowToInstallPython/HowToInstallPython';
import { CheckIfPythonWasSuccessfullyInstalled } from '../../ChapterModules/Practise/CheckIfPythonWasSuccessfullyInstalled/CheckIfPythonWasSuccessfullyInstalled';
import { InstallIde } from '../../ChapterModules/Practise/InstallIde/InstallIde';

const LookupFunctionPractise = () => {
  const { id } = useParams();

  function getPageById(id) {
    switch (parseInt(id)) {
      case 1:
        return HowToInstallPython;
      case 2:
        return CheckIfPythonWasSuccessfullyInstalled;
      case 3:
        return InstallIde;
      default:
        return HowToInstallPython;
    }
  }

  return (
    <>
      <PractisePage chapterObj={getPageById(id)} />
    </>
  );
};

LookupFunctionPractise.propTypes = {};

export default LookupFunctionPractise;
