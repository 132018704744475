import React, { useState, useCallback, useMemo, useEffect } from 'react';
import './PractisePage.scss';
import classNames from 'classnames';
import 'react-medium-image-zoom/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateChapterProgress } from '../../Api/actions/chapterProgress-actions';
import Fab from '../../Components/organisms/Fab/Fab';
import Button from '../../Components/atoms/Button/Button';
import useDetectDevice from '../../Functionality/Hooks/useDetectDevice';

const PractisePage = ({ chapterObj }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWidth = useDetectDevice(768);

  useEffect(() => {
    if (currentWidth) {
      setSelectedTab('Theory');
    } else {
      setSelectedTab('Exercises');
    }
  }, [currentWidth]);

  const tabs = ['Theory', 'Exercises', 'Code snippet'];

  const { details, chapters } = chapterObj;

  const [selectedChapterId, setSelectedChapterId] = useState(1);
  const [selectedTab, setSelectedTab] = useState('Exercises');

  const selectedChapterVideo = useMemo(() => {
    return (
      chapterObj &&
      chapters &&
      chapters.find((chapter) => chapter.chapterId === selectedChapterId).videoSrc
    );
  }, [selectedChapterId, chapters, chapterObj]);

  const selectedChapterContent = useMemo(() => {
    if (!chapterObj || !chapters) return null;

    const chapter = chapters.find((data) => data?.chapterId === selectedChapterId);
    if (!chapter?.chapterContent) return null;

    return chapter.chapterContent.map((chapterSection, index) => (
      <span key={index}>{chapterSection}</span>
    ));
  }, [chapterObj, chapters, selectedChapterId]);

  const openLink = useCallback(() => {
    if (chapters) {
      window.open(
        chapters
          .filter((data) => data?.chapterId === selectedChapterId)
          .map((htmlElements) => {
            return htmlElements?.chapterActivityLink;
          }),
        '_blank'
      );
    }
  }, [chapters, selectedChapterId]);

  function navigateBreadCrumb(path) {
    navigate(path);
  }

  const onUpdateChapterProgress = useCallback(() => {
    dispatch(updateChapterProgress(details.name, selectedChapterId));
  }, [dispatch, details.name, selectedChapterId]);

  function onClickTab(newTab) {
    setSelectedTab(newTab);
  }

  function handleSelectChapter(chapterId) {
    setSelectedChapterId(chapterId);
  }

  function handleNextChapter() {
    setSelectedChapterId((prev) => prev + 1);
  }

  function handlePreviousChapter() {
    setSelectedChapterId((prev) => prev - 1);
  }

  return (
    <>
      <section className="ace-practisePage">
        <div className="ace-practisePage__breadCrumbsContainer">
          <span onClick={() => navigateBreadCrumb('/')}>Learn</span>
          <span>❯</span>
          <span onClick={() => navigateBreadCrumb('/chapters')}>Class X</span>
          <span>❯</span>
          <span>{chapterObj && details && details.name}</span>
        </div>
        <div className={classNames('ace-practisePage__gridOne')}>
          <div className={classNames('ace-practisePage__chapterTitleMobile')}>
            {chapterObj &&
              chapters &&
              chapters
                .filter((data) => data?.chapterId === selectedChapterId)
                .map((htmlElements) => {
                  return (
                    <span key={htmlElements?.chapterId}>
                      {/* Chapter {htmlElements?.chapterId} -  */}
                      {htmlElements?.chapterTitle}
                    </span>
                  );
                })}
          </div>
          <div className={classNames('ace-practisePage__gridTwo')}>
            <div className={classNames('ace-practisePage__box1')}>
              <div className="video-container">
                <iframe
                  src={`https://player.vimeo.com/video/${selectedChapterVideo}?transparent=0&title=0&portrait=0`}
                  title={selectedChapterVideo}
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen></iframe>
              </div>
            </div>
            <div className={classNames('ace-practisePage__box3')}>
              {tabs &&
                tabs.map((tab) => (
                  <div
                    onClick={() => onClickTab(tab)}
                    className={classNames('ace-practisePage__tabs', {
                      'ace-practisePage__tabs--selected': tab === selectedTab,
                      'ace-practisePage__tabs--mobileOnly': tab === 'Theory'
                    })}>
                    {tab}
                  </div>
                ))}
            </div>
            <div className={classNames('ace-practisePage__box4')}>
              {selectedTab === 'Theory' && chapterObj && (
                <div className="ace-practisePage__chapterContent">
                  <div className="ace-practisePage__chapterContentBottom">
                    {selectedChapterContent}
                  </div>
                  <div className="ace-practisePage__navItemsContainer">
                    <Button
                      type="button"
                      className={classNames('ace-practisePage__navItems', {
                        'ace-practisePage__navItems--disabled': selectedChapterId === 1
                      })}
                      disabled={selectedChapterId === 1}
                      handleClick={handlePreviousChapter}>
                      Back
                    </Button>
                    <Button
                      type="button"
                      className={classNames('ace-practisePage__navItems', {
                        'ace-practisePage__navItems--disabled': chapterObj
                          ? selectedChapterId === chapters.length
                          : false
                      })}
                      disabled={chapterObj ? selectedChapterId === chapters.length : false}
                      handleClick={handleNextChapter}>
                      Next
                    </Button>
                  </div>
                </div>
              )}
              {selectedTab === 'Exercises' &&
                chapterObj &&
                chapters &&
                chapters
                  .filter((data) => data?.chapterId === selectedChapterId)
                  .map((chapter) => {
                    return <>{chapter.exercise}</>;
                  })}
              {selectedTab === 'Code snippet' &&
                chapterObj &&
                chapters &&
                chapters
                  .filter((data) => data?.chapterId === selectedChapterId)
                  .map((chapter) => {
                    return <>{chapter.codeSnippet}</>;
                  })}
            </div>
            <div className="ace-practisePage__progressBarBox">
              <div className="ace-practisePage__progressBar">
                {chapterObj &&
                  chapters &&
                  chapters.map((htmlElements) => {
                    return (
                      <div
                        key={htmlElements?.chapterId}
                        className={classNames('ace-practisePage__progressBarBit', {
                          'ace-practisePage__progressBarBit--filled':
                            htmlElements?.chapterId <= selectedChapterId
                        })}></div>
                    );
                  })}
              </div>
              <Fab
                isStaticOnMobile={true}
                menuItems={
                  chapterObj &&
                  chapters &&
                  chapters.map((htmlElements) => {
                    return {
                      id: htmlElements?.chapterId,
                      title: `${htmlElements?.chapterTitle}`
                    };
                  })
                }
                onClickItem={handleSelectChapter}
                selectedChapterId={selectedChapterId}
              />
            </div>
          </div>
          <div className={classNames('ace-practisePage__box2')}>
            <div className="ace-practisePage__chapterTitle">
              {chapterObj &&
                chapters &&
                chapters
                  .filter((data) => data?.chapterId === selectedChapterId)
                  .map((htmlElements) => {
                    return (
                      <span key={htmlElements?.chapterId}>
                        {/* Chapter {htmlElements?.chapterId} -  */}
                        {htmlElements?.chapterTitle}
                      </span>
                    );
                  })}
            </div>
            <div className="ace-practisePage__chapterContent">{selectedChapterContent}</div>
            <div className="ace-practisePage__navItemsContainer">
              <Button
                type="button"
                className={classNames('ace-practisePage__navItems', {
                  'ace-practisePage__navItems--disabled': selectedChapterId === 1
                })}
                disabled={selectedChapterId === 1}
                handleClick={handlePreviousChapter}>
                Back
              </Button>
              <Button
                type="button"
                className={classNames('ace-practisePage__navItems', {
                  'ace-practisePage__navItems--disabled': chapterObj
                    ? selectedChapterId === chapters.length
                    : false
                })}
                disabled={chapterObj ? selectedChapterId === chapters.length : false}
                handleClick={handleNextChapter}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

PractisePage.propTypes = {};

export default PractisePage;
