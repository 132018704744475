import {
    CodeSnippet,
    singleColTextArr,
    MultiImageRow
  } from '../../../Utils/ComponentFactories';
  import step1 from './1.png';
  import step2 from './2.png';
  import step3 from './3.png';
  import step4 from './4.png';

  let codeSnippet1 = `
  No code for this section
  `;
  let codeSnippet2 = `
  python
  `;
  
  export const CheckIfPythonWasSuccessfullyInstalled = {
    details: {
      name: 'Check if python was successfully installed'
    },
    chapters: [
      {
        chapterTitle: 'Step 1',
        chapterId: 1,
        chapterContent: [
          singleColTextArr({
            textArr: [
              `In the windows search type “cmd”`
            ]
          }),
          MultiImageRow(
            {
              images: [{ src: step1, alt: 'Step 1' }]
            },
            true,
            false
          )
        ],
        codeSnippet: CodeSnippet(codeSnippet1),
        exercise: 'No exercise for this section',
        videoSrc: 1026591871
      },
      {
        chapterTitle: 'Step 2',
        chapterId: 2,
        chapterContent: [
          singleColTextArr({
            textArr: [
              `Click on “Open”`
            ]
          }),
          MultiImageRow(
            {
              images: [{ src: step2, alt: 'Step 2' }]
            },
            true,
            false
          )
        ],
        codeSnippet: CodeSnippet(codeSnippet1),
        exercise: 'No exercise for this section',
        videoSrc: 1026591871
      },
      {
        chapterTitle: 'Step 3',
        chapterId: 3,
        chapterContent: [
          singleColTextArr({
            textArr: [
              `Now type “python” into the window and click enter`
            ]
          }),
          MultiImageRow(
            {
              images: [{ src: step3, alt: 'Step 3' }]
            },
            true,
            false
          )
        ],
        codeSnippet: CodeSnippet(codeSnippet2),
        exercise: 'No exercise for this section',
        videoSrc: 1026591871
      },
      {
        chapterTitle: 'Step 4',
        chapterId: 4,
        chapterContent: [
          singleColTextArr({
            textArr: [
              `Now you should see a python version number appear as shown below. This shows that python was installed successfully`
            ]
          }),
          MultiImageRow(
            {
              images: [{ src: step4, alt: 'Step 4' }]
            },
            true,
            false
          )
        ],
        codeSnippet: CodeSnippet(codeSnippet1),
        exercise: 'No exercise for this section',
        videoSrc: 1026591871
      },
    ]
  };
  