import {
  RECEIVE_LOGIN,
  LOG_OUT,
  SET_SIGNIN_LOADING,
  SET_SIGNIN_SUCCESS,
  SET_SIGNIN_ERROR,
  SET_GET_USER_DATA_LOADING,
  SET_GET_USER_DATA_SUCCESS,
  SET_GET_USER_DATA_ERROR,
  SET_SIGNUP_LOADING,
  SET_SIGNUP_SUCCESS,
  SET_SIGNUP_ERROR
} from '../../Constants/action-types';

import { login, callAjaxWCognito, getUserPool, callAjax } from '../../Functionality/CognitoTMX';
import {
  // SIGN_UP_GET_COMPANIES,
  GET_USER_DETAILS,
  SIGN_UP_USER
  // FORGOT_PASSWORD,
  // TMX_ACCESS_PRICE_DETAILS,
} from '../../Functionality/networking';

// import {
//   setAccountVerified,
//   bypassEmailVerification,
//   callVerifyLoading,
// } from "./signUp-actions";
// import { getCurrentContentId, removeCurrentContentId } from "./details-actions";
// import { fetchAllCollections } from "./feed-actions";

export function receiveLogIn(data) {
  return {
    type: RECEIVE_LOGIN,
    userData: data
  };
}

function setLogOut() {
  return {
    type: LOG_OUT
  };
}

function setSignInLoading() {
  return {
    type: SET_SIGNIN_LOADING
  };
}

function setSignInSuccess() {
  return {
    type: SET_SIGNIN_SUCCESS
  };
}

function setSignInError() {
  return {
    type: SET_SIGNIN_ERROR
  };
}

function setSignUpLoading() {
  return {
    type: SET_SIGNUP_LOADING
  };
}

function setSignUpSuccess() {
  return {
    type: SET_SIGNUP_SUCCESS
  };
}

function setSignUpError() {
  return {
    type: SET_SIGNUP_ERROR
  };
}

function setGetUserDataLoading() {
  return {
    type: SET_GET_USER_DATA_LOADING
  };
}

function setGetUserDataSuccess(userData) {
  return {
    type: SET_GET_USER_DATA_SUCCESS,
    userData: userData
  };
}

function setGetUserDataError() {
  return {
    type: SET_GET_USER_DATA_ERROR
  };
}

export function signIn(username, password, callBack = null) {
  return (dispatch) => {
    dispatch(setSignInLoading());

    const handleSuccess = (data) => {
      dispatch(getUserData());
      dispatch(setSignInSuccess());
      if (callBack) {
        callBack();
      }
    };
    const handleError = (error) => {
      dispatch(setSignInError(error));
    };
    const handleChangePassword = () => {
      //   dispatch(mustChangePassword());
    };
    login(username, password, handleSuccess, handleError, handleChangePassword);
  };
}

export function signUpUser(username, password, callBack = null) {
  return (dispatch) => {
    dispatch(setSignUpLoading());

    function handleSuccessLogin() {
      dispatch(getUserData());
      if (callBack) {
        callBack();
      }
    }

    const handleSuccess = () => {
      login(username, password, handleSuccessLogin, null, null);
      dispatch(setSignUpSuccess());
    };
    const handleError = (error) => {
      dispatch(setSignUpError(error));
    };

    const signUpData = {
      email: username,
      password: password
    };

    callAjax(
      SIGN_UP_USER,
      handleSuccess,
      handleError,
      JSON.stringify({
        action: 'sign_up',
        email: signUpData['email'],
        password: signUpData['password']
      })
    );
  };
}

export function logOut() {
  return (dispatch) => {
    const cognitoUser = getUserPool().getCurrentUser();
    if (cognitoUser !== null) {
      dispatch(setLogOut());
      cognitoUser.signOut();
      window.localStorage.clear();
      window.location.replace('/');
    }
  };
}

export function getUserData(setLoggedIn, handleRedirect) {
  return (dispatch) => {
    dispatch(setGetUserDataLoading());
    const handleSuccess = (response) => {
      const parsedResponse = JSON.parse(response);
      if (parsedResponse.success) {
        const currentDate = new Date();
        const userData = {
          ...JSON.parse(localStorage.getItem('userData'))?.value,
          ...parsedResponse
        };

        const dataToSet = JSON.stringify({
          value: userData,
          expiry: currentDate.getTime() + 31556952000
        });

        localStorage.setItem('userData', dataToSet);

        dispatch(setGetUserDataSuccess(dataToSet));

        if (handleRedirect && JSON.parse(localStorage.getItem('userData'))?.value) {
          handleRedirect();
        }
      }
    };

    const handleError = (error) => {
      dispatch(setGetUserDataError(error));
    };

    callAjaxWCognito(GET_USER_DETAILS, handleSuccess, handleError, JSON.stringify({}));
  };
}
