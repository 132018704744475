import {
  RECEIVE_LOGIN,
  RESET_SIGN_IN_REDUCER,
  SET_SIGNIN_LOADING,
  SET_SIGNIN_SUCCESS,
  SET_SIGNIN_ERROR,
  SET_SIGNUP_LOADING,
  SET_SIGNUP_SUCCESS,
  SET_SIGNUP_ERROR,
  LOG_OUT,
  SET_GET_USER_DATA_LOADING,
  SET_GET_USER_DATA_SUCCESS,
  SET_GET_USER_DATA_ERROR,
} from "../../Constants/action-types";
import {
  refreshUserAttributes,
  isLoggedIn,
} from "../../Functionality/CognitoTMX";

function getWithExpiry(asBoolean) {
  // asBoolean is used to define if the return value needs to be a boolean or not
  const userData = localStorage.getItem("userData");
  const isTokenPresent = isLoggedIn();
  const requiredTokensPresent =
    userData && JSON.parse(userData)?.value?.email ? !!isTokenPresent : false;

  if (userData && asBoolean && !requiredTokensPresent) {
    window.localStorage.clear();
    return false;
  } else if (!userData && !asBoolean) {
    return null;
  } else if (!userData && asBoolean) {
    return false;
  }
  const userRelatedData = JSON.parse(userData);
  const currentDate = new Date();
  if (currentDate.getTime() > userRelatedData.expiry && !asBoolean) {
    localStorage.removeItem("userData");
    return null;
  } else if (currentDate.getTime() > userRelatedData.expiry && asBoolean) {
    return false;
  }
  if (!asBoolean) {
    refreshUserAttributes();
    return userRelatedData.value;
  } else {
    return true;
  }
}

// function errorCheck(asBoolean) {
//   const userData = localStorage.getItem("userData");
//   const requiredTokensPresent =
//     userData && JSON.parse(userData)?.value?.accessToken?.payload
//       ? !!localStorage.getItem(
//           "CognitoIdentityServiceProvider." + CLIENT_ID + ".LastAuthUser"
//         ) &&
//         !!localStorage.getItem(
//           "CognitoIdentityServiceProvider." +
//             CLIENT_ID +
//             "." +
//             JSON.parse(userData).value.accessToken.payload.username +
//             ".idToken"
//         ) &&
//         !!localStorage.getItem(
//           "CognitoIdentityServiceProvider." +
//             CLIENT_ID +
//             "." +
//             JSON.parse(userData).value.accessToken.payload.username +
//             ".clockDrift"
//         ) &&
//         !!localStorage.getItem(
//           "CognitoIdentityServiceProvider." +
//             CLIENT_ID +
//             "." +
//             JSON.parse(userData).value.accessToken.payload.username +
//             ".accessToken"
//         ) &&
//         !!localStorage.getItem(
//           "CognitoIdentityServiceProvider." +
//             CLIENT_ID +
//             "." +
//             JSON.parse(userData).value.accessToken.payload.username +
//             ".refreshToken"
//         )
//       : false;
//   if (userData && asBoolean && !requiredTokensPresent) {
//     return true;
//   } else {
//     return false;
//   }
// }

const initialState = {
  error: false,
  loading: false,
  loggedIn: getWithExpiry(true),
  //   showLoginModalForError: errorCheck(true),
  signUpIncompleteExceptionError: false,
  userData: getWithExpiry(false)
    ? JSON.parse(localStorage.getItem("userData")).value
    : null,
  userLoading: false,
  username: "Steve",
  signInLoading: false,
  signInSuccess: false,
  signInError: false,
  signUpLoading: false,
  signUpSuccess: false,
  signUpError: false,
  getUserDataLoading: false,
  getUserDataSuccess: false,
  getUserDataError: false,
};

export default function signInReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LOGIN:
      return {
        ...initialState,
        loggedIn: true,
        userData: action.userData,
        signInLoading: false,
        userLoading: false,
      };
    case RESET_SIGN_IN_REDUCER:
      return {
        ...initialState,
      };

    case SET_SIGNIN_LOADING:
      return {
        ...state,
        signInLoading: true,
        signInSuccess: false,
        signInError: false,
      };
    case SET_SIGNIN_SUCCESS:
      return {
        ...state,
        signInLoading: false,
        signInSuccess: true,
        signInError: false,
      };
    case SET_SIGNIN_ERROR:
      return {
        ...state,
        signInLoading: false,
        signInSuccess: false,
        signInError: true,
      };
    case SET_SIGNUP_LOADING:
      return {
        ...state,
        signUpLoading: true,
        signUpSuccess: false,
        signUpError: false,
      };
    case SET_SIGNUP_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
        signUpSuccess: true,
        signUpError: false,
      };
    case SET_SIGNUP_ERROR:
      return {
        ...state,
        signUpLoading: false,
        signUpSuccess: false,
        signUpError: true,
      };

    case SET_GET_USER_DATA_LOADING:
      return {
        ...state,
        getUserDataLoading: true,
        getUserDataSuccess: false,
        getUserDataError: false,
      };
    case SET_GET_USER_DATA_SUCCESS:
      return {
        ...initialState,
        getUserDataLoading: false,
        getUserDataSuccess: true,
        getUserDataError: false,
        userData: action.userData,
        loggedIn: true,
      };
    case SET_GET_USER_DATA_ERROR:
      return {
        ...state,
        getUserDataLoading: false,
        getUserDataSuccess: false,
        getUserDataError: true,
      };

    case LOG_OUT:
      return { ...initialState, loggedIn: false, userData: null };
    default:
      return state;
  }
}
