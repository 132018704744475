import IS_TEST from './IsTest';

export const API_BASE_URL = IS_TEST
  ? 'https://5v671b6az2.execute-api.ap-south-1.amazonaws.com/api/' //dev
  : 'https://5v671b6az2.execute-api.ap-south-1.amazonaws.com/api/'; //prod
export const PROD_API_BASE_URL = 'https://5v671b6az2.execute-api.ap-south-1.amazonaws.com/api/';

//endpoints
export const SIGN_UP_USER = API_BASE_URL + 'auth';
export const GET_USER_DETAILS = API_BASE_URL + 'get-user-details';
export const FORGOT_PASSWORD = API_BASE_URL + 'sign-up-reset-password';

// Cognito                                  dev                     prod
export const USER_POOL_ID = IS_TEST ? 'ap-south-1_5Fn0Q9yBf' : 'ap-south-1_5Fn0Q9yBf';
export const CLIENT_ID = IS_TEST ? '3no60h3f5kb5sulf62njdmpdil' : '3no60h3f5kb5sulf62njdmpdil';
