import React from 'react';
import { useNavigate } from 'react-router-dom';

import './TextbookPage.scss';

const TextbookPage = ({ navigateTo }) => {
  const navigate = useNavigate();

  const textbookArray = [
    {
      id: 1,
      name: 'Introduction to CS'
    },
  ];

  function onNavigate() {
    if (navigateTo === 'practise') {
      navigate('/practise-language')
    } else {
      navigate('/chapters')
    }
  }

  return (
    <>
      <section className="ace-TextbookPage">
        <div className="ace-TextbookPage__chapterContainer">
          <div>Textbook:</div>
          <div className="ace-TextbookPage__horizontalLine" />

          {textbookArray &&
            textbookArray.map((textbook) => {
              return (
                <div
                  key={textbook?.id}
                  onClick={onNavigate}
                  className="ace-TextbookPage__chapterBox">
                  <p>
                    {textbook?.name}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="ace-TextbookPage__bookIcon">
                    <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
                  </svg>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
};

TextbookPage.propTypes = {};

export default TextbookPage;
