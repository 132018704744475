import { useRef, useState } from 'react';
import { useEffect } from 'react';

export default function useFab() {
  const [isFabButtonClicked, setIsFabButtonClicked] = useState(false);
  const fabRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const target = event.target;
      console.log(fabRef);

      if (fabRef.current && !fabRef.current.contains(target)) {
        setIsFabButtonClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFabButtonClicked, setIsFabButtonClicked]);

  return {
    isFabButtonClicked,
    setIsFabButtonClicked,
    fabRef
  };
}
