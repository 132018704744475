import React from 'react';
import fabButton from './fab-button.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Fab.scss';
import useFab from '../../../Functionality/Hooks/useFab';

const Fab = ({
  isStaticOnMobile = false,
  menuItems = ['Chapter 1 - Convulsions', 'Chapter 1 - Convulsions'],
  onClickItem,
  isHiddenOnDesktop = false,
  selectedChapterId
}) => {
  const { isFabButtonClicked, setIsFabButtonClicked, fabRef } = useFab();

  function wrappedOnClickItem(clicked) {
    onClickItem(clicked);
    setIsFabButtonClicked(false);
  }

  return (
    <div
      ref={fabRef}
      className={classNames('ace-fabIcon__fabButtonContainer', {
        'ace-fabIcon__fabButtonContainer--active': isFabButtonClicked,
        'ace-fabIcon__fabButtonContainer--static': isStaticOnMobile,
        'ace-fabIcon__fabButtonContainer--hiddenDesktop': isHiddenOnDesktop
      })}>
      <div
        className={classNames('ace-fabIcon__fabIconContainerSecondary', {
          'ace-fabIcon__fabIconContainerSecondary--active': isFabButtonClicked
        })}>
        <div className={classNames('ace-fabIcon__fabMenuContainer')}>
          {menuItems &&
            menuItems.map((item) => (
              <div
                key={item.id}
                onClick={() => wrappedOnClickItem(item.id)}
                className={classNames('ace-fabIcon__fabMenuContainerItems', {
                  'ace-fabIcon__fabMenuContainerItems--selected': item.id === selectedChapterId
                })}>
                {item.title}
              </div>
            ))}
        </div>
      </div>
      <div
        className={classNames('ace-fabIcon__fabIconContainerPrimary', {
          'ace-fabIcon__fabIconContainerPrimary--active': isFabButtonClicked
        })}
        onClick={() => {
          setIsFabButtonClicked(!isFabButtonClicked);
        }}
        onKeyDown={() => {
          setIsFabButtonClicked(!isFabButtonClicked);
        }}
        role="button"
        tabIndex={0}>
        <img src={fabButton} className="ace-fabIcon__standardImage" alt="fabButton" />
      </div>
    </div>
  );
};

Fab.propTypes = {
  isStaticOnMobile: PropTypes.bool,
  menuItems: PropTypes.array,
  onClickItem: PropTypes.func,
  selectedChapterId: PropTypes.number
};

export default Fab;
