import { UPDATE_PROGRESS } from '../../Constants/action-types';

function setUpdatedChapterProgress(chapterProgress) {
  return {
    type: UPDATE_PROGRESS,
    chapterProgress: chapterProgress
  };
}

export function updateChapterProgress(chapterName, subChapterName) {
  return (dispatch, state) => {
    console.log(state().chapterProgressReducer.chapterProgress);
    const chapterProgress = { ...state().chapterProgressReducer.chapterProgress };

    if (!chapterProgress[chapterName]) {
      chapterProgress[chapterName] = new Set();
    }
    chapterProgress[chapterName].add(subChapterName);
    dispatch(setUpdatedChapterProgress(chapterProgress));
  };
}
