import React, { useCallback } from 'react';
import './App.scss';

import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Navigation from './Components/organisms/Navigation/Navigation';
import LoginPage from './Pages/LoginPage/LoginPage';
import LookupFunctionLearn from './Pages/LookupFunction/LookupFunctionLearn';
import ChapterPageLearn from './Pages/ChapterPage/ChapterPageLearn'; 
import ChapterPagePractise from './Pages/ChapterPage/ChapterPagePractise'; 
import ClassPage from './Pages/ClassPage/ClassPage';
import LanguagePage from './Pages/LanguagePage.js/LanguagePage';
import LookupFunctionPractise from './Pages/LookupFunction/LookupFunctionPractise';
import TextbookPage from './Pages/TextbookPage/TextbookPage';

function App() {
  const { loggedIn } = useSelector((state) => state.signInReducer);

  const ProtectedRoute = useCallback(({ loggedIn, children }) => {
    if (!loggedIn) {
      return <Navigate to="/login" replace />;
    }

    return children;
  }, []);

  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        {/* Learn page routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <ClassPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chapters"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <ChapterPageLearn />
            </ProtectedRoute>
          }
        />
        <Route
          path="/textbook"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <TextbookPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/theoryContentDetail/:id"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <LookupFunctionLearn />
            </ProtectedRoute>
          }
        />

        {/* Practise page Routes */}
        <Route
          path="/practise-class"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <ClassPage navigateTo="practise" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/practise-textbook"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <TextbookPage navigateTo="practise" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/practise-language"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <LanguagePage navigateTo="practise" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/practise-chapters"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <ChapterPagePractise />
            </ProtectedRoute>
          }
        />
        <Route
          path="/practise/:id"
          element={
            <ProtectedRoute loggedIn={loggedIn}>
              <LookupFunctionPractise />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
