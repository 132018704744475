import array from './array.jpg';
import { TwoColTextImage } from '../../../Utils/ComponentFactories';

export const DataStructures = {
  details: {
    name: 'DataStructures'
  },
  chapters: [
    {
      chapterTitle: 'Array',
      chapterId: 1,
      chapterContent: [
        TwoColTextImage({
          text: 'An array is a fundamental data structure that stores elements of the same type in contiguous memory locations. It allows random access to elements using an index, which makes searching for elements fast (O(1) time complexity). However, arrays are of fixed size, meaning their length is set at the time of creation and cannot be changed. Operations like insertion and deletion can be slow because elements need to be shifted if they are not performed at the end of the array. Arrays are used in various applications like storing multiple items of the same type, implementing other data structures, and for algorithms like sorting and searching.',
          image: array,
          alt: 'logo'
        }),
        TwoColTextImage({
          text: 'An array is a fundamental data structure that stores elements of the same type in contiguous memory locations. It allows random access to elements using an index, which makes searching for elements fast (O(1) time complexity). However, arrays are of fixed size, meaning their length is set at the time of creation and cannot be changed. Operations like insertion and deletion can be slow because elements need to be shifted if they are not performed at the end of the array. Arrays are used in various applications like storing multiple items of the same type, implementing other data structures, and for algorithms like sorting and searching.',
          image: array,
          alt: 'logo'
        }),
        TwoColTextImage({
          text: 'An array is a fundamental data structure that stores elements of the same type in contiguous memory locations. It allows random access to elements using an index, which makes searching for elements fast (O(1) time complexity). However, arrays are of fixed size, meaning their length is set at the time of creation and cannot be changed. Operations like insertion and deletion can be slow because elements need to be shifted if they are not performed at the end of the array. Arrays are used in various applications like storing multiple items of the same type, implementing other data structures, and for algorithms like sorting and searching.',
          image: array,
          alt: 'logo'
        }),
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Linked List',
      chapterId: 2,
      chapterContent: [
        TwoColTextImage({
          text: 'A linked list is a linear data structure where each element, called a node, contains a data part and a reference (or pointer) to the next node in the sequence. Unlike arrays, linked lists do not require a contiguous block of memory, allowing for dynamic memory allocation. There are different types of linked lists: singly linked lists, where each node points to the next one; doubly linked lists, where each node points to both the next and the previous nodes; and circular linked lists, where the last node points back to the first node. While linked lists allow efficient insertion and deletion (O(1) at the head or tail), random access to elements is slow, as it requires traversal of the list (O(n) time complexity).',
          image: array,
          alt: 'logo'
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Stack',
      chapterId: 3,
      chapterContent: [
        TwoColTextImage({
          text: 'A stack is a linear data structure that follows the Last In, First Out (LIFO) principle. This means that the last element added to the stack is the first one to be removed. Stacks are commonly used in scenarios such as function call management in recursion, undo mechanisms in text editors, and parsing expressions in compilers. Operations on a stack typically include push (adding an item to the top), pop (removing the top item), and peek (viewing the top item without removing it). Stacks are easy to implement using arrays or linked lists and offer O(1) time complexity for both insertion and deletion.',
          image: array,
          alt: 'logo'
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    }
  ]
};
