import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import temp from './temp.png';

import './LoginPage.scss';
// import classNames from "classnames";

import { signIn, signUpUser } from '../../Api/actions/signIn-actions';
import Login from '../../Components/organisms/Login/Login';
import SignUp from '../../Components/organisms/SignUp/SignUp';
import { EMAIL_REGEX } from '../../Constants/regex';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signInLoading, signInError, loggedIn, signUpLoading, signUpError } = useSelector(
    (state) => state.signInReducer
  );

  const [userNameValue, setUserNameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');

  const [isLogin, setIsLogin] = useState(true);

  const notifySent = useCallback(() => {
    console.log('Navigating.................');
    if (loggedIn) {
      navigate('/loginSuccess');
    }
  }, [navigate, loggedIn]);

  const login = useCallback(() => {
    dispatch(signIn(userNameValue, passwordValue, notifySent));
  }, [dispatch, userNameValue, passwordValue, notifySent]);

  const signUp = useCallback(() => {
    dispatch(signUpUser(userNameValue, passwordValue, notifySent));
  }, [dispatch, userNameValue, passwordValue, notifySent]);

  const onUsernameChange = (event) => {
    setUserNameValue(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPasswordValue(event.target.value);
  };

  const onConfirmPasswordChange = (event) => {
    setConfirmPasswordValue(event.target.value);
  };

  const onSubmit = () => login();

  const onClickRegister = () => setIsLogin(false);

  const onClickLogin = () => setIsLogin(true);

  const isSignInValid = () => {
    return EMAIL_REGEX.test(userNameValue) && passwordValue.length >= 6;
  };

  const isSignUpValid = () => {
    return (
      EMAIL_REGEX.test(userNameValue) &&
      passwordValue.length >= 6 &&
      confirmPasswordValue.length >= 6 &&
      confirmPasswordValue === passwordValue
    );
  };

  return (
    <>
      <section className="ace-loginPage">
        <div className="ace-loginPage__loginBlock">
          <div className="ace-loginPage__loginBaseConatiner">
            <div className="ace-loginPage__loginTitle">Welcome !</div>
            <div className="ace-loginPage__subheadingContainer">
              <div className="ace-loginPage__subheadingOne">Sign in to</div>
              <div className="ace-loginPage__subheadingTwo">ACE Learning App</div>
            </div>
            {isLogin && (
              <Login
                onUsernameChange={onUsernameChange}
                usernameValue={userNameValue}
                onPasswordChange={onPasswordChange}
                passwordValue={passwordValue}
                onSubmit={onSubmit}
                signInLoading={signInLoading}
                signInError={signInError}
                onClickRegister={onClickRegister}
                isFormValid={isSignInValid}
              />
            )}
            {!isLogin && (
              <SignUp
                onUsernameChange={onUsernameChange}
                usernameValue={userNameValue}
                onPasswordChange={onPasswordChange}
                passwordValue={passwordValue}
                onConfirmPasswordChange={onConfirmPasswordChange}
                confirmPasswordValue={confirmPasswordValue}
                onSubmit={signUp}
                signInLoading={signUpLoading}
                signInError={signUpError}
                onClickLogin={onClickLogin}
                isFormValid={isSignUpValid}
              />
            )}
          </div>
        </div>
        <div className="ace-loginPage__sliderContainer">
          <img src={temp} className="ace-loginPage__tempImage" alt="logo" />
        </div>
      </section>
    </>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
