export const LOG_OUT = 'LOG_OUT';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const RESET_SIGN_IN_REDUCER = 'RESET_SIGN_IN_REDUCER';

export const SET_SIGNIN_LOADING = 'SET_SIGNIN_LOADING';
export const SET_SIGNIN_SUCCESS = 'SET_SIGNIN_SUCCESS';
export const SET_SIGNIN_ERROR = 'SET_SIGNIN_ERROR';

export const SET_SIGNUP_LOADING = 'SET_SIGNUP_LOADING';
export const SET_SIGNUP_SUCCESS = 'SET_SIGNUP_SUCCESS';
export const SET_SIGNUP_ERROR = 'SET_SIGNUP_ERROR';

export const SET_GET_USER_DATA_LOADING = 'SET_GET_USER_DATA_LOADING';
export const SET_GET_USER_DATA_SUCCESS = 'SET_GET_USER_DATA_SUCCESS';
export const SET_GET_USER_DATA_ERROR = 'SET_GET_USER_DATA_ERROR';

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
