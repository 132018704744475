import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.scss';

const Icon = ({ name, size, className, onClick, iconText, iconTextValue, iconTextClassName }) => {
  const iconClassName = classNames(`tmx-icon-${name}`, `icon`, size, className);

  const clickCallback = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  const renderPath = () => {
    let pathElements = [];
    // Create a loop of 20 to render multiple path based
    // on the count expected on _tmx-icon.scss
    for (let i = 1; i <= 20; i++) {
      pathElements.push(<span className={`path${i}`} key={i}></span>);
    }
    return pathElements;
  };

  return (
    <>
      {name === 'logo-big' ||
      name === 'logo-small' ||
      name === 'tmx-brand-logo-beta' ||
      name === 'tmx-brand-mark-beta-centered' ||
      name === 'tmx-brand-mark-beta' ||
      name === 'newmaker-step-two' ||
      name === 'newmaker-step-three' ||
      name === 'newmaker-step-four' ||
      name === 'newmaker-step-one' ||
      name === 'media-step-three' ||
      name === 'media-step-four' ||
      name === 'media-step-one' ||
      name === 'media-step-two' ||
      name === 'linkedin' ||
      name === 'instagram' ||
      name === 'home-page-banner' ||
      name === 'home-page-media' ||
      name === 'home-page-newsmaker' ||
      name === 'telemundo' ||
      name === 'warner-media' ||
      name === 'why-tmx-one' ||
      name === 'why-tmx-two' ||
      name === 'why-tmx-three' ||
      name === 'why-tmx-four' ||
      name === 'twitter' ||
      name === 'twitter-gray' ||
      name === 'facebook' ||
      name === 'our-values-one' ||
      name === 'our-values-two' ||
      name === 'our-values-three' ||
      name === 'our-values-four' ||
      name === 'publicist-one' ||
      name === 'publicist-four' ||
      name === 'publicist-two' ||
      name === 'publicist-three' ||
      name === 'tiktok' ||
      name === 'youtube' ||
      name === 'reddit_circle' ||
      name === 'instagram_circle' ||
      name === 'credit-card' ||
      name === 'bank' ||
      name === 'tik-tok' ||
      name === 'Instagram3' ||
      name === 'newFB' ||
      name === 'profile-image-www' ||
      name === 'profile-image-you-tube' ||
      name === 'profile-image-tmx' ||
      name === 'tmx-pitches' ||
      name === 'refer' ? (
        <div
          className={iconClassName}
          onClick={clickCallback}
          role="button"
          tabIndex="-1"
          aria-hidden="true"
          id={name}>
          {renderPath()}
        </div>
      ) : name === 'dont-care' ? (
        <div
          className={iconClassName}
          onClick={clickCallback}
          role="button"
          tabIndex="-1"
          aria-hidden="true"
          id={name}>
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
      ) : name === 'sort-up' ||
        name === 'sort-down' ||
        name === 'twitter' ||
        name === 'facebook_circle' ? (
        <div
          className={iconClassName}
          onClick={clickCallback}
          role="button"
          tabIndex="-1"
          aria-hidden="true"
          id={name}>
          <span className="path1"></span>
          <span className="path2"></span>
        </div>
      ) : (
        <span
          onClick={clickCallback}
          className={iconClassName}
          role="button"
          tabIndex="-1"
          aria-hidden="true"
          id={name}>
          {iconText ? <span className={iconTextClassName}>{iconTextValue}</span> : null}
        </span>
      )}
    </>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    '',
    '16-article',
    '16-image',
    '16-images',
    '4nbc',
    'Instagram3',
    'Play',
    'Settings',
    'UGC',
    'acquired',
    'agent',
    'agent1',
    'alerts',
    'amex',
    'arrow-alt-from-bottom',
    'arrow-link-outside',
    'arrow-right',
    'article',
    'bank',
    'bell-on',
    'blocked',
    'calendar',
    'camera-1',
    'camera-2',
    'card',
    'check',
    'check-circle',
    'check1',
    'chevron',
    'choose-placement',
    'cleared',
    'cleared-1',
    'cleared-articles',
    'cleared-videos',
    'clock',
    'clock-alt',
    'close',
    'cloud',
    'cloud-download',
    'cloud-download_small',
    'cloud-upload_big',
    'cloud-upload_small',
    'cnn',
    'code',
    'code1',
    'coffee-pot_big',
    'coffee-pot_small',
    'combined-shape',
    'contact',
    'contacts',
    'content',
    'credit-card',
    'delete',
    'discover',
    'doc-pdf',
    'doc-word',
    'dont-care',
    'downloaded',
    'edit-org',
    'edit-organization',
    'ellipsis',
    'email-alerts',
    'envelope',
    'everyone',
    'eye-slash-solid',
    'eye-slash-solid',
    'facebook',
    'facebook_circle',
    'file-alt',
    'file-error',
    'file-error',
    'file-syndication',
    'fire-alt_big',
    'fire-alt_small',
    'flag',
    'follow-organization',
    'follow-star',
    'fox-news',
    'globe',
    'group-2',
    'group-41',
    'handshake-alt',
    'hide',
    'home',
    'home-page-banner',
    'home-page-media',
    'home-page-newsmaker',
    'icon1',
    'id-card',
    'image',
    'impress',
    'info',
    'instagram',
    'instagram_circle',
    'link-solid',
    'linked-blk',
    'linkedin',
    'location',
    'location-international',
    'location-tag',
    'locationBanner',
    'lock',
    'lock-open',
    'logo-big',
    'logo-small',
    'map-marked',
    'map-marker',
    'marketing-arrow',
    'marketplace-buy',
    'marketplace-cleared',
    'mastercard',
    'match',
    'match2',
    'media-step-four',
    'media-step-one',
    'media-step-three',
    'media-step-two',
    'membership',
    'memo-circle-info',
    'menu',
    'meredith',
    'message',
    'messages-2',
    'metadata',
    'metrics',
    'minus',
    'minus-circle',
    'nbcuniversal',
    'new-icon-share',
    'new-tmx-loader',
    'newFB',
    'newmaker-step-four',
    'newmaker-step-one',
    'newmaker-step-three',
    'newmaker-step-two',
    'newsrooms',
    'notification',
    'notification',
    'nrr',
    'org-icon',
    'our-values-four',
    'our-values-one',
    'our-values-three',
    'our-values-two',
    'paid',
    'panel',
    'paper',
    'paperclip',
    'passport',
    'payment',
    'payment',
    'people',
    'people1',
    'phone',
    'photo-gallery',
    'pitch-2',
    'pitch-2-1',
    'pitch-alt-1',
    'pitches-2',
    'play',
    'play-circle',
    'plus',
    'plus-circled',
    'popular',
    'popular-inv',
    'popular-inv-2',
    'powered-by-TMX',
    'prev-sent',
    'preview-card',
    'profile-image-tmx',
    'profile-image-www',
    'profile-image-you-tube',
    'promo-code',
    'publicist-four',
    'publicist-one',
    'publicist-three',
    'publicist-two',
    'purchased',
    'q',
    'question',
    'radar',
    'radio',
    'radio-center',
    'radio-fill',
    'radio-filled',
    'recommended',
    'reddit_circle',
    'referral',
    'refresh1',
    'request-icon',
    'retry',
    'retry-circled',
    'search',
    'search1',
    'send',
    'send-request-2',
    'settings',
    'shape',
    'share',
    'shared-1',
    'show',
    'sidebar-flip',
    'sign-out',
    'sitemap',
    'social-spotter',
    'social-spotter1',
    'sort-default',
    'sort-down',
    'sort-up',
    'stop-watch',
    'stories',
    'stories-2',
    'story-contacts',
    'stripe',
    'tags',
    'target',
    'targeted',
    'tegna',
    'telemundo',
    'the-weather-company',
    'thumb-down',
    'thumb-down-filled',
    'thumb-down1',
    'thumb-up',
    'thumb-up-filled',
    'thumb-up1',
    'tik-tok',
    'tiktok',
    'title-license',
    'title-shared',
    'tmx-brand-logo-beta',
    'tmx-brand-mark-beta',
    'tmx-brand-mark-beta-centered',
    'tmx-pitches',
    'tmx-plain-logo',
    'top-nav-upload',
    'tracker-org',
    'trash',
    'trash-1',
    'trash-icon',
    'trash1',
    'tray-cleared',
    'tray-discover',
    'tray-inbox',
    'tray-licensed',
    'tray-no-use',
    'tray-targeted',
    'tv',
    'twitter',
    'twitter-gray',
    'ugc',
    'upgrade',
    'upgrade-bolt',
    'upload-pitch',
    'uploaded',
    'user',
    'user-blk',
    'user-check_big',
    'user-check_small',
    'user-circle',
    'user-icon',
    'user-menu',
    'user-plus',
    'users',
    'verified',
    'video',
    'video-2',
    'views',
    'views-2',
    'visa',
    'warner-media',
    'why-tmx-four',
    'why-tmx-one',
    'why-tmx-three',
    'why-tmx-two',
    'x-circled',
    'youtube',
    'arrow-right2',
    'facebook-new',
    'twitter-new',
    'linkedin-new',
    'instagram-new',
    'tracker-link',
    'youtube-new',
    'wave',
    'refer',
    'refer-new',
    'expert-substack',
    'expert-youtube',
    'expert-facebook',
    'expert-web',
    'calendar-new',
    'send-profile',
    'messages',
    'profile-activity',
    'add-quote',
    'pa-called',
    'pa-opened',
    'pa-click-link',
    'pa-cliked-email',
    'pa-profile',
    'pa-tv',
    'sizzle',
    'cliked-email',
    'called2',
    'click-link',
    'profile1',
    'opened',
    'views1',
    'read-entire',
    'schedule-calendar',
    'called',
    'envelope1',
    'mail',
    'clicked',
    'phone2'
  ]).isRequired,
  size: PropTypes.oneOf([
    'micro',
    'small',
    'medium',
    'eighteen',
    'twenty',
    'twentytwo',
    'large',
    'huge',
    'ginormous',
    'fourtyOne',
    'fourtyfour',
    'fiftysix'
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconText: PropTypes.bool,
  iconTextValue: PropTypes.string,
  iconTextClassName: PropTypes.string
};

Icon.defaultProps = {
  iconText: false
};

export default Icon;
