import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Button.scss";

import Icon from "../../atoms/Icon/Icon";

const Button = ({
  buttonIcon,
  buttonType,
  children,
  className,
  disabled,
  handleClick,
  handleFocus,
  iconClassName,
  iconDynamicClassName,
  iconSize,
  spinner,
  name,
  success,
  type,
  spinnerPosition = "left",
}) => {
  const classes = classNames(
    {
      "ace-button": buttonType !== "blue",
      "ace-button--blue": buttonType === "blue",
      "ace-button--grey": buttonType === "grey",
      "ace-button--greyBlack": buttonType === "grey-black",
      "ace-button--white": buttonType === "white",
      "ace-button--orange": buttonType === "orange",
      "ace-button--purple": buttonType === "purple",
      spinning: spinner,
      success: success || buttonIcon === "check1" || buttonIcon === "check",
      "ace-button--disabled": disabled,
    },
    className
  );
  const callback = useCallback(
    (event) => {
      if (handleClick) {
        handleClick(event);
      }
    },
    [handleClick]
  );
  const focusCallback = useCallback(
    (event) => {
      if (handleFocus) {
        handleFocus(event);
      }
    },
    [handleFocus]
  );

  return (
    <button
      type={type}
      onClick={callback}
      className={classes}
      disabled={disabled}
      id={name || ""}
      onFocus={focusCallback}
    >
      {spinner && spinnerPosition === "left" ? (
        <span
          className={classNames(
            "ace-button__spinner",
            `ace-button__spinner-${iconSize}`
          )}
        ></span>
      ) : (
        <></>
      )}
      {buttonIcon ? (
        <Icon
          name={buttonIcon}
          className={classNames(
            "ace-button__extraIcon",
            `ace-button__${iconClassName}`,
            iconDynamicClassName
          )}
          size={iconSize}
        />
      ) : (
        <></>
      )}
      {children}
      {spinner && spinnerPosition === "right" ? (
        <span
          className={classNames(
            "ace-button__spinner",
            `ace-button__spinner-${iconSize}`
          )}
        ></span>
      ) : (
        <></>
      )}
    </button>
  );
};

Button.propTypes = {
  buttonIcon: PropTypes.string,
  buttonType: PropTypes.oneOf([
    "blue",
    "grey",
    "white",
    "grey-black",
    "orange",
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleFocus: PropTypes.func,
  iconClassName: PropTypes.string,
  iconDynamicClassName: PropTypes.string,
  iconSize: PropTypes.string,
  name: PropTypes.string,
  spinner: PropTypes.bool,
  spinnerPosition: PropTypes.string,
  success: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
};

export default Button;
