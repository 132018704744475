import {
  CodeSnippet,
  // Exercise,
  singleColTextArr,
  MultiImageRow
} from '../../../Utils/ComponentFactories';
import step1 from './1.png';
import step2 from './2.png';
import step3 from './3.png';
import step4 from './4.png';
import step5 from './5.png';
import step6 from './6.png';
import step7 from './7.png';

let codeSnippet1 = `
No code for this chapter
`;
// let codeSnippet3 = `
// # Set up code checking 3
// import os
// if not os.path.exists("../input/train.csv"):
//     os.symlink("../input/home-data-for-ml-course/train.csv", "../input/train.csv")
//     os.symlink("../input/home-data-for-ml-course/test.csv", "../input/test.csv")
// from learntools.core import binder
// binder.bind(globals())
// from learntools.ml_intermediate.ex1 import *
// print("Setup Complete")
// `;

export const HowToInstallPython = {
  details: {
    name: 'How to install python'
  },
  chapters: [
    {
      chapterTitle: 'Step 1',
      chapterId: 1,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Open your web browser and google "Python" and click on the URL "https://www.python.org/"`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step1, alt: 'Step 1' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
      // Exercise({
      //   problem:
      //     'Write a Python program that accepts a sentence from the user and counts the number of vowels and consonants in it.'
      // })
    },
    {
      chapterTitle: 'Step 2',
      chapterId: 2,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Move your mouse cursor to "Downloads" and click on the button below "Download for Windows". This will start the download`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step2, alt: 'Step 2' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
    },
    {
      chapterTitle: 'Step 3',
      chapterId: 3,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Once the download is completed click on the downloaded file, this will start the install`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step3, alt: 'Step 3' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
    },
    {
      chapterTitle: 'Step 4',
      chapterId: 4,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `You will now see an install wizard open up. Check both the boxes you see. And then click “Customize installation”`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step4, alt: 'Step 4' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
    },
    {
      chapterTitle: 'Step 5',
      chapterId: 5,
      chapterContent: [
        singleColTextArr({
          textArr: [`Click the “next” button`]
        }),
        MultiImageRow(
          {
            images: [{ src: step5, alt: 'Step 5' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
    },
    {
      chapterTitle: 'Step 6',
      chapterId: 6,
      chapterContent: [
        singleColTextArr({
          textArr: [
            `Check the box for “Install python for all users”, then click the “Install” button and wait for the installation to complete`
          ]
        }),
        MultiImageRow(
          {
            images: [{ src: step6, alt: 'Step 6' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
    },
    {
      chapterTitle: 'Step 7',
      chapterId: 7,
      chapterContent: [
        singleColTextArr({
          textArr: [`Click on “Disable path length limit”, then the “Close” button. `]
        }),
        MultiImageRow(
          {
            images: [{ src: step7, alt: 'Step 7' }]
          },
          true,
          false
        )
      ],
      codeSnippet: CodeSnippet(codeSnippet1),
      exercise: 'No exercise for this section',
      videoSrc: 1026591254
    }
  ]
};
