import React from 'react';
import { useNavigate } from 'react-router-dom';

import './LanguagePage.scss';

const LanguagePage = ({ navigateTo }) => {
  const navigate = useNavigate();

  function onNavigate() {
    if (navigateTo === 'practise') {
      navigate('/practise-chapters')
    } else {
      navigate('/chapters')
    }
  }

  return (
    <>
      <section className="ace-LanguagePage">
        <div className="ace-LanguagePage__classContainer">
          <div>Language:</div>
          <div className="ace-LanguagePage__horizontalLine" />
          <div onClick={onNavigate} className="ace-LanguagePage__classItem">Python</div>
        </div>
      </section>
    </>
  );
};

LanguagePage.propTypes = {};

export default LanguagePage;
