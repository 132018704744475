import React, { useState, useCallback } from 'react';
import './LearnPage.scss';
import classNames from 'classnames';
import Button from '../../Components/atoms/Button/Button';
import 'react-medium-image-zoom/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateChapterProgress } from '../../Api/actions/chapterProgress-actions';
import Fab from '../../Components/organisms/Fab/Fab';

const LearnPage = ({ chapterObj }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { details, chapters } = chapterObj;

  const [isPageMaximized, setIsPageMaximized] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(1);

  const openLink = useCallback(() => {
    if (chapters) {
      window.open(
        chapters
          .filter((data) => data?.chapterId === selectedChapterId)
          .map((htmlElements) => {
            return htmlElements?.chapterActivityLink;
          }),
        '_blank'
      );
    }
  }, [chapters, selectedChapterId]);

  function maximizeMinimizePage() {
    setIsPageMaximized(!isPageMaximized);
  }

  function navigateBreadCrumb(path) {
    navigate(path);
  }

  const onUpdateChapterProgress = useCallback(() => {
    dispatch(updateChapterProgress(details.name, selectedChapterId));
  }, [dispatch, details.name, selectedChapterId]);

  function handleNextChapter() {
    setSelectedChapterId((prev) => prev + 1);
  }

  function handlePreviousChapter() {
    setSelectedChapterId((prev) => prev - 1);
  }

  function handleSelectChapter(chapterId) {
    setSelectedChapterId(chapterId);
  }

  return (
    <>
      <section className="ace-theoryContentPage">
        <Fab
          menuItems={
            chapterObj &&
            chapters &&
            chapters.map((htmlElements) => {
              return {
                id: htmlElements?.chapterId,
                title: `${htmlElements?.chapterTitle}`
              };
            })
          }
          onClickItem={handleSelectChapter}
          isHiddenOnDesktop={true}
        />
        <div className="ace-theoryContentPage__breadCrumbsContainer">
          <span onClick={() => navigateBreadCrumb('/')}>Learn</span>
          <span>❯</span>
          <span onClick={() => navigateBreadCrumb('/chapters')}>Class X</span>
          <span>❯</span>
          <span>{chapterObj && details && details.name}</span>
        </div>
        <div
          className={classNames('ace-theoryContentPage__gridOne', {
            'ace-theoryContentPage__gridOne--maximized': isPageMaximized
          })}>
          <div
            className={classNames('ace-theoryContentPage__box1', {
              'ace-theoryContentPage__box1--maximized': isPageMaximized
            })}>
            <div className="ace-theoryContentPage__chapterTitle">
              {/* Chapter 04 - Convolution{' '} */}
              {chapterObj &&
                chapters &&
                chapters
                  .filter((data) => data?.chapterId === selectedChapterId)
                  .map((htmlElements) => {
                    return (
                      <span key={htmlElements?.chapterId}>
                        Chapter {htmlElements?.chapterId} - {htmlElements?.chapterTitle}
                      </span>
                    );
                  })}
            </div>
            <div className="ace-theoryContentPage__chapterContent">
              {chapterObj &&
                chapters
                  ?.filter((data) => data?.chapterId === selectedChapterId)[0]
                  ?.chapterContent?.map((chapterSection, index) => (
                    <span key={index}>{chapterSection}</span>
                  ))}
            </div>
            <div className="ace-theoryContentPage__maximizeContainer">
              <div className="ace-theoryContentPage__maximizeContainerItemsContainer">
                <Button
                  type="button"
                  className={classNames('ace-theoryContentPage__navItems', {
                    'ace-theoryContentPage__navItems--disabled': selectedChapterId === 1
                  })}
                  disabled={selectedChapterId === 1}
                  handleClick={handlePreviousChapter}>
                  Back
                </Button>
                <Button
                  type="button"
                  className={classNames('ace-theoryContentPage__navItems', {
                    'ace-theoryContentPage__navItems--disabled': chapterObj
                      ? selectedChapterId === chapters.length
                      : false
                  })}
                  disabled={chapterObj ? selectedChapterId === chapters.length : false}
                  handleClick={handleNextChapter}>
                  Next
                </Button>
              </div>
              <div className="ace-theoryContentPage__maximizeContainerItemsContainer">
                <div
                  className="ace-theoryContentPage__iconInnerContainer"
                  onClick={maximizeMinimizePage}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={classNames('ace-theoryContentPage__maximizeIcons', {
                      'ace-theoryContentPage__maximizeIcons--hidden': isPageMaximized
                    })}>
                    <polyline points="15 3 21 3 21 9" />
                    <polyline points="9 21 3 21 3 15" />
                    <line x1="21" x2="14" y1="3" y2="10" />
                    <line x1="3" x2="10" y1="21" y2="14" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={classNames('ace-theoryContentPage__maximizeIcons', {
                      'ace-theoryContentPage__maximizeIcons--hidden': !isPageMaximized
                    })}>
                    <polyline points="4 14 10 14 10 20" />
                    <polyline points="20 10 14 10 14 4" />
                    <line x1="14" x2="21" y1="10" y2="3" />
                    <line x1="3" x2="10" y1="21" y2="14" />
                  </svg>
                </div>
                <div className="ace-theoryContentPage__iconInnerContainer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="ace-theoryContentPage__maximizeIcons">
                    <path d="M2 10v3" />
                    <path d="M6 6v11" />
                    <path d="M10 3v18" />
                    <path d="M14 8v7" />
                    <path d="M18 5v13" />
                    <path d="M22 10v3" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="ace-theoryContentPage__box3Mobile">
              <div className="ace-theoryContentPage__linkTitle">Convolution Activity</div>
              <div className="ace-theoryContentPage__linkButtonContainer">
                <Button
                  buttonType="blue"
                  className="ace-theoryContentPage__linkButton"
                  type="button"
                  handleClick={openLink}
                  // disabled={!isFormValid() || signInLoading}
                  // spinner={signInLoading}
                >
                  Start Activity
                </Button>
              </div>
            </div>
          </div>
          <div
            className={classNames('ace-theoryContentPage__box2', {
              'ace-theoryContentPage__box2--maximized': isPageMaximized
            })}>
            <div className="ace-theoryContentPage__listTitle">Chapters</div>
            <div>
              <ol className="ace-theoryContentPage__listContainer">
                {chapterObj &&
                  chapters &&
                  chapters.map((htmlElements) => {
                    return (
                      <li
                        onClick={() => {
                          setSelectedChapterId(htmlElements?.chapterId);
                        }}
                        onKeyDown={() => {
                          setSelectedChapterId(htmlElements?.chapterId);
                        }}
                        role="button"
                        tabIndex={0}
                        className={classNames('ace-theoryContentPage__listItem', {
                          'ace-theoryContentPage__listItem--selected':
                            selectedChapterId === htmlElements?.chapterId
                        })}
                        key={htmlElements?.chapterId}>
                        {htmlElements?.chapterTitle}
                      </li>
                    );
                  })}
              </ol>
            </div>
          </div>
        </div>

        <div
          className={classNames('ace-theoryContentPage__gridTwo', {
            'ace-theoryContentPage__gridTwo--maximized': isPageMaximized
          })}>
          <div
            className={classNames({
              'ace-theoryContentPage__box3Maximized': isPageMaximized,
              'ace-theoryContentPage__box3': !isPageMaximized
            })}>
            <div className="ace-theoryContentPage__linkTitle">Convolution Activity</div>
            <div className="ace-theoryContentPage__linkButtonContainer">
              <Button
                buttonType="blue"
                className="ace-theoryContentPage__linkButton"
                type="button"
                handleClick={openLink}
                // disabled={!isFormValid() || signInLoading}
                // spinner={signInLoading}
              >
                Start Activity
              </Button>
            </div>
            {/* <div onClick={onUpdateChapterProgress}>Next</div> */}
          </div>
          <div
            className={classNames({
              'ace-theoryContentPage__box4Maximized': isPageMaximized,
              'ace-theoryContentPage__box4': !isPageMaximized
            })}>
            <div className="ace-theoryContentPage__progressBar">
                {chapterObj &&
                  chapters &&
                  chapters.map((htmlElements) => {
                    return (
                      <div
                        key={htmlElements?.chapterId}
                        className={classNames('ace-theoryContentPage__progressBarBit', {
                          'ace-theoryContentPage__progressBarBit--filled':
                            htmlElements?.chapterId <= selectedChapterId
                        })}></div>
                    );
                  })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

LearnPage.propTypes = {};

export default LearnPage;
