import grayscale1 from './greyscale1.jpg';
import grayscale2 from './greyscale2.jpg';
import horizontal from './horizontal.jpg';
import vertical from './vertical.jpg';
import kernel from './kernel.png';
import pixels from './pixels.png';
import rgb from './rgb.jpg';
import cnn from './cnn.jpg';
import applications from './applications.png';
import convolution from './convolution.png';
import {
  MultiImageRow,
  questionAnswerBlock,
  quote,
  singleColTextArr,
  SingleColTextWithBullets,
  TwoColTextImage,
  TwoColTextImageWrap
} from '../../../Utils/ComponentFactories';

export const ComputerVision = {
  details: {
    name: 'ComputerVision'
  },
  chapters: [
    {
      chapterTitle: 'Introduction',
      chapterId: 1,
      chapterContent: [
        quote({
          text: '“Just like how to HEAR is not the same as to LISTEN, to TAKE PICTURES is not the same as to SEE”',
          author: 'Dr. Fei-Fei Li'
        }),
        singleColTextArr({
          textArr: [
            'Computer vision is a pretty self explanatory term, the ability of an AI or a software to look at images and videos and translate those images into a binary code that will help it understand what it is looking at in its own terms is what is regarded as Computer vision or CV.',
            'The ability to let machines the world and understand the world has been something scientists and engineers have been working on as early as the 1960’s*',
            'Now thanks to all the advancements in technology, AI programs now have the ability to look at real world images and videos and to analyse images and videos in real time. This is what is used in things such as Face ID, filters on social media and even self driving cars.',
            'CV is one of the fundamental means by which Artificial Intelligence interfaces with the real world.'
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'What is computer vision?',
      chapterId: 2,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'Just like how us human beings look at the world and form a picture of it through our senses like touch, sight and smell, the ability of a computer to perceive its surroundings and locate, analyse and identify the information it receives is what we call computer vision, this is either done through pictures or even real time videos.',
            'The ability of an AI to look at an image and identify all the features in it and not only form a comprehensive idea of what it is, but also to identify all the key features in that image, whether that is its ability to read the expressions on someone’s face or to identify if there is a cancer cell in an MRI scan, computer vision plays a key role when it comes to the ability of an Artificially intelligent machine to extrapolate and extract data from an image or even a live video, in real time.'
          ]
        }),
        questionAnswerBlock({
          question: 'How do computers see this world?',
          textArr: [
            'So if we can recollect what pixels are and start from there we can start to have a good idea of how CV (computer vision) works.'
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Pixels',
      chapterId: 3,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'The word ‘Pixel’ is a combination of the words ‘picture’ and ‘element’.',
            'Therefore any data that is some sort of visual media, it is stored as a pixel and multitudes of these pixels form a complete picture.',
            'Each image on a screen is stored as a pixel and huge grids of pixels form a complete image.',
            'Each of these single pixels each has a colour value assigned to it.',
            'These pixel colour values are a combination of the colour values of the 3 primary colours i.e. Red, Green & Blue, otherwise known as RGB values.',
            'Each pixel can therefore have a value of any real number between 0 to 255 with 0 being no colour or black and 255 being the brightest colour or white.',
            'The combination of the three numerical values of the R, G and B colours is what gives each pixel its specific colour.',
            'The colour of the pixel you see on the screen is the combination of the colour values of these three colours.',
            'By modifying the intensities and colour values of each of the RGB values we are able to represent all the colours on the spectrum on any screen.',
            'This is how all colour is produced in any of the digital screens that you see.'
          ]
        }),
        MultiImageRow({
          images: [{ src: pixels, alt: 'Pixels' }]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'RGB values',
      chapterId: 4,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'Assuming that there is only one person with that colour on their self in the entire room then the algorithm will keep successfully tracking them.',
            "The problem then with this sort of simple algorithm then becomes that if there enters anyone else with a similar coloured accessory, it will not be able to differentiate between the bow-tie and let's say the lady that just walked in with the red dress.",
            'This is one of the reasons why colour marker tracking is rarely used unless it is being operated in a tightly controlled environment with no other variables.'
          ]
        }),
        MultiImageRow({
          images: [{ src: rgb, alt: 'Rgb' }]
        }),
        singleColTextArr({
          textArr: [
            'Assuming that there is only one person with that colour on their self in the entire room then the algorithm will keep successfully tracking them.',
            "The problem then with this sort of simple algorithm then becomes that if there enters anyone else with a similar coloured accessory, it will not be able to differentiate between the bow-tie and let's say the lady that just walked in with the red dress.",
            'This is one of the reasons why colour marker tracking is rarely used unless it is being operated in a tightly controlled environment with no other variables.'
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Grey Scale',
      chapterId: 5,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'One of the simpler solutions to the problem posed by the inability of AI to accurately track through colour is why real world algorithms are a little bit more complex in nature.',
            'The easiest way to solve this is to simply remove the colours from the equation, most AI that does things like real time video processing and such look at the world not in colour but in grey-scale.',
            'The way AI developers tackle this particular problem is like this through the use of something called patches.',
            'Because one image is rarely just a single pixel let alone a few and most objects take up multiple pixels in a screen, computers identify small regions of pixels called patches.',
            'Although most algorithms now can work in colour, for the sake of an example let us use a picture which is turned into gray scale.',
            'Now if an AI wants to identify in real time if it is moving through a field which is an object and which is not, it identifies one patch, takes note of the colour gradient in that grid and notices patterns of colours that persist through multiple pixels.'
          ]
        }),
        MultiImageRow({
          images: [
            { src: grayscale1, alt: 'Original' },
            { src: grayscale2, alt: 'Greyscale' }
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Kernels',
      chapterId: 6,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'In order to understand more about patches let us first delve into Kernels.',
            'Let us say a tree is in the path of a drone, that drone is going to analyse the video in front of it and it will notice that a persistent pattern of colours is repeating through multiple pixels and patches.',
            'It will have a good idea of what the edges of that object are and will be able to at least form a comprehensive outline of that object.',
            'A formula for identifying the colour difference in a patch is something like this.',
            'The likelihood of there being an object with vertical and horizontal edges is based on the magnitude between the difference in colour between <n> number of pixels to its left, right, above and below.',
            'If there is a very minor colour difference then it is likely that it is not an object at all.',
            'There is a mathematical notation for this which is called either a kernel or a filter.'
          ]
        }),
        MultiImageRow({
          images: [{ src: kernel, alt: 'Kernel' }]
        }),
        singleColTextArr({
          textArr: [
            'So when this kernel is overlaid with the image already input then by using a simple mathematical function like this, by using the RGB values of the colours involved, we can get an idea if there is an object right in front of the drone or not, just by looking at the kernel grid.',
            'There are some specific formulas used for very specific purposes. We will look at the edge detecting formula for horizontal and vertical edge detection, these are called prewitt operators which we shall get into now.'
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Prewitt Operators',
      chapterId: 7,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'The kernel function that lets objects identify horizontal edges is called Prewitt operators, named after its inventor.',
            'The discontinuity in the image brightness is called an edge. Edge detection is the technique used to identify the regions in the image where the brightness of the image changes sharply.  Also, this change in colour persists along many rows vertically and this is more formally defined as The likelihood of a pixel being an edge is the magnitude of difference in colour between some pixels to its left and some pixels to its right.',
            'We use the prewitt operators for horizontal and vertical edges, each patch will help identify objects thanks to their edges so an image like this will look like the following based on which filter or patch is being applied'
          ]
        }),
        MultiImageRow({
          images: [{ src: grayscale1, alt: 'Original Image' }]
        }),
        MultiImageRow({
          images: [
            { src: horizontal, alt: 'Horizontal edge detection' },
            { src: vertical, alt: 'Vertical Edge detection' }
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Convolutions',
      chapterId: 8,
      chapterContent: [
        TwoColTextImageWrap({
          textArr: [
            'The process of applying a Kernel to a patch of pixels is called a convolution.',
            'Convolutions are one of the most critical, fundamental building blocks in computer vision and image processing.',
            'We learned that computers store images in numbers and that pixels are arranged in a particular manner to create the picture we can recognize.',
            'As we change the values of these pixels, the image changes. Image convolution is simply an element-wise multiplication of two matrices followed by a sum. Convolution is using a ‘kernel’ to extract certain ‘features’ from an input image.'
          ],
          image: convolution,
          alt: 'logo'
        }),
        // singleColTextArr({
        //   textArr: [
        //     'The process of applying a Kernel to a patch of pixels is called a convolution.',
        //     'Convolutions are one of the most critical, fundamental building blocks in computer vision and image processing.',
        //     'We learned that computers store images in numbers and that pixels are arranged in a particular manner to create the picture we can recognize. As we change the values of these pixels, the image changes. Image convolution is simply an element-wise multiplication of two matrices followed by a sum. Convolution is using a ‘kernel’ to extract certain ‘features’ from an input image.'
        //   ]
        // }),
        SingleColTextWithBullets({
          text: 'Now let’s see the steps of convolution:',
          bullets: [
            'Take two matrices (Input Image +kernel with dimensions).',
            'Multiply them, element-by-element (i.e.,not the dot-product, just a simple multiplication).',
            'Sum the elements together.',
            'Then the sum will be the center value of the image'
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Convolution Neural Network',
      chapterId: 9,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'By using multiple convolutions each operating under different parameters we are able to get a whole comprehensive picture.',
            'Thanks to the power of computing an AI can run multiple convolutions in real time and identify an ever-changing landscape with unbelievable accuracy.',
            'When multiple convolutions are layered together they form something called a convolution neural network, most of the AI we use on a daily basis that uses cameras or images and videos operate using some form of CNN'
          ]
        }),
        MultiImageRow({
          images: [{ src: cnn, alt: 'Convolution Neural Network' }]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    },
    {
      chapterTitle: 'Applications of computer vision',
      chapterId: 10,
      chapterContent: [
        singleColTextArr({
          textArr: [
            'Currently there are a lot of day to day activities we perform which are being aided by computer vision and AI and as the technology grows daily there are newer and more innovative ideas and applications that come out of CV.'
          ]
        }),
        SingleColTextWithBullets({
          text: 'Even today Computer Vision is used in multiple things like :',
          bullets: [
            'Facial recognition',
            'face filters',
            'google image search',
            'retail stores',
            'self driving cars',
            'medical imaging',
            'google translate'
          ]
        }),
        MultiImageRow({
          images: [{ src: applications, alt: 'Applications of computer vision' }]
        }),
        singleColTextArr({
          textArr: [
            'The best and worst problem with CV and its applications is now just one thing, it is being held back not by the technology but with the inability to find apt use cases for it.',
            'The growth of this technology in the past few years and the coming decade has been exponential and the technology itself will continue to better itself dramatically, now to catch up to that technology we have to find new ways to use these tools afforded to us and apply that in various different fields and sectors so that we can help improve the lives of not just ourselves but everyone around us.',
            'The future of AI in and of itself is now heavily dependent on us, the humans and our ability to integrate these new technologies into our society so that we can lead better lives.'
          ]
        })
      ],
      chapterActivityLink:
        'https://www.kaggle.com/code/davidashraf/exercise-convolutions-for-computer-vision/notebook'
    }
  ]
};
