import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Input.scss';

import Button from '../Button/Button';

const Input = ({
  autoComplete,
  autoFocus,
  checked,
  className,
  darkBorder,
  disabled,
  disablePastTime,
  error,
  greyBorder,
  handleClickCall,
  htmlFor,
  iconSize,
  iconStyle,
  id,
  inputIcon,
  inputPrefix,
  inputPrefixClassName,
  inputRef,
  isDate,
  label,
  labelClassName,
  labelContainerClassName,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  placeholder,
  required,
  setScheduled,
  setShowDateAndTime,
  textarea,
  type,
  value,
  statusbar,
  stickyButton,
  customInputContainerClassName,
  handleButtonSpinner,
  handleButtondisabled,
  buttonText,
  stickyButtonCustomClassname
}) => {
  const [inputType, setInputType] = useState('');
  const [count, setCount] = useState(0);
  const [countError, setCountError] = useState(false);
  const [iconType, setIconType] = useState('eye-slash-solid');
  const classes = classNames('ace-input', className, {
    grey: greyBorder,
    checkbox: type === 'checkbox',
    dark: darkBorder,
    dateTime: disablePastTime,
    error
  });
  const stickyButtonClassname = classNames('ace-input__stickyButton', stickyButtonCustomClassname);

  useEffect(() => {
    if (disablePastTime) {
      var today = new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(':'));
      document.getElementsByClassName('dateTime')[0].setAttribute('min', today);
    }
  }, [disablePastTime]);

  const callback = useCallback(
    (event) => {
      if (!maxLength) {
        onChange(event);
      } else {
        setCount(event.target.value.length);
        onChange(event);

        if (event.target.value.length > maxLength) {
          setCountError(true);
        } else {
          setCountError(false);
        }
      }
    },
    [onChange, maxLength]
  );
  const keyDownCallback = useCallback(
    (event) => {
      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [onKeyDown]
  );
  const keyUpCallback = useCallback(
    (event) => {
      if (onKeyUp) {
        onKeyUp(event);
      }
    },
    [onKeyUp]
  );
  const blurCallback = useCallback(
    (event) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );
  const focusCallback = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <div
      className={classNames('ace-input__wrapper', {
        'ace-input__wrapper--checkbox': type === 'checkbox',
        'ace-input__wrapper--radio': type === 'radio'
      })}>
      {(label || error) && (
        <div className={classNames('ace-input__labelWrapper', labelContainerClassName)}>
          <div>
            {label && (
              <label
                className={classNames(
                  'ace-input__uploadFormLabel',
                  labelClassName === 'signup'
                    ? 'ace-input__uploadFormLabel--signup'
                    : labelClassName
                )}
                htmlFor={htmlFor}>
                {label} {required ? <sup> *</sup> : null}
              </label>
            )}
          </div>
          {error && <div className="ace-input__errorMessage">{error}</div>}
          {isDate && (
            <button
              onClick={() => {
                setScheduled(false);
                setShowDateAndTime(false);
              }}>
              Go back, send now
            </button>
          )}
          {statusbar && (
            <div
              className={classNames('ace-input__statusBar', {
                error: error || countError
              })}>
              <p className="ace-input__counterPara">
                {count}/{maxLength}
              </p>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(
          'ace-input__inputContainer',
          { showPrefix: inputPrefix },
          customInputContainerClassName
        )}>
        {inputPrefix && (
          <div className={classNames('ace-input__inputPrefix', inputPrefixClassName)}>
            {inputPrefix}
          </div>
        )}
        {textarea ? (
          <textarea
            className={classes}
            type={inputType}
            placeholder={placeholder}
            onChange={callback}
            onKeyDown={keyDownCallback}
            onKeyUp={keyUpCallback}
            onBlur={blurCallback}
            onFocus={focusCallback}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            id={id}
            name={name}
            value={value}
            autoComplete={autoComplete}
            ref={inputRef}
            max={max}
            maxLength={maxLength}
            // eslint-disable-next-line react/no-unknown-property
            checked={checked}
            disabled={disabled}
          />
        ) : (
          <>
            <input
              className={classes}
              type={inputType}
              placeholder={placeholder}
              onChange={callback}
              onKeyDown={keyDownCallback}
              onKeyUp={keyUpCallback}
              onBlur={blurCallback}
              onFocus={focusCallback}
              id={id}
              name={name}
              value={value}
              autoComplete={autoComplete}
              ref={inputRef}
              max={max}
              min={min}
              maxLength={maxLength}
              checked={checked}
              disabled={disabled}
            />
            {stickyButton ? (
              <Button
                type="button"
                className={stickyButtonClassname}
                buttonType="blue"
                handleClick={() => {
                  if (handleClickCall) {
                    handleClickCall();
                  }
                }}
                spinner={handleButtonSpinner}
                disabled={handleButtondisabled}>
                {buttonText}
              </Button>
            ) : null}
          </>
        )}
      </div>

      {(type === 'password' || name === 'routingNumber' || name === 'accountNumber') && (
        <Button
          className="ace-input__viewIconCta"
          type="button"
          buttonIcon={iconType}
          iconSize="small"
          handleClick={(event) => {
            event.preventDefault();
            setIconType(iconType === 'eye-slash-solid' ? 'views' : 'eye-slash-solid');
            setInputType(iconType === 'views' ? 'password' : 'text');
          }}></Button>
      )}
      {inputIcon ? (
        <Button
          className={classNames('ace-input__viewIconCta', iconStyle)}
          type="button"
          buttonIcon={inputIcon}
          handleClick={() => {
            if (handleClickCall) {
              handleClickCall();
            }
          }}
          iconSize={iconSize}></Button>
      ) : (
        <></>
      )}
    </div>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  darkBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  disablePastTime: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  greyBorder: PropTypes.bool,
  handleClickCall: PropTypes.any,
  htmlFor: PropTypes.string,
  iconSize: PropTypes.string,
  iconStyle: PropTypes.string,
  id: PropTypes.string,
  inputIcon: PropTypes.string,
  inputPrefix: PropTypes.string,
  inputPrefixClassName: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element)
    })
  ]),
  isDate: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelContainerClassName: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setScheduled: PropTypes.func,
  setShowDateAndTime: PropTypes.func,
  statusbar: PropTypes.bool,
  textarea: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'checkbox', 'password', 'date', 'radio', 'number']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  stickyButton: PropTypes.bool,
  customInputContainerClassName: PropTypes.string,
  handleButtonSpinner: PropTypes.bool,
  handleButtondisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  stickyButtonCustomClassname: PropTypes.string
};

Input.defaultProps = {
  greyBorder: false,
  type: 'text',
  id: '',
  name: '',
  value: '',
  autoComplete: 'off',
  iconStyle: '',
  handleClickCall: '',
  stickyButton: false
};

export default Input;
