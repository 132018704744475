import React from 'react';
import { useParams } from 'react-router-dom';
import LearnPage from '../LearnPage/LearnPage';
import { ComputerVision } from '../../ChapterModules/Learn/ComputerVision/ComputerVision'
import { DataStructures } from '../../ChapterModules/Learn/DataStructures/DataStructures'

const LookupFunctionLearn = () => {
  const { id } = useParams();

  function getPageById(id) {
    switch (parseInt(id)) {
      case 1:
        return ComputerVision;
      case 2:
        return DataStructures;
      default:
        return ComputerVision;
    }
  }

  return (
    <>
      <LearnPage chapterObj={getPageById(id)} />
    </>
  );
};

LookupFunctionLearn.propTypes = {};

export default LookupFunctionLearn;
