import { UPDATE_PROGRESS } from '../../Constants/action-types';

const initialState = {
  chapterProgress: new Map()
};

export default function chapterProgressReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...initialState,
        chapterProgress: action.chapterProgress
      };
    default:
      return state;
  }
}
