import React, { useEffect, useCallback, useState } from 'react';
// import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';

import { getUserData } from '../../../Api/actions/signIn-actions';
import { logOut } from '../../../Api/actions/signIn-actions';

import Button from '../../../Components/atoms/Button/Button';

import classNames from 'classnames';
import logo from './logo.png';
import mobileLogo from './mobileLogo.png';
import leaderboard from './leaderboard.png';
import rewardpoints from './rewardpoints.png';
import profile from './profile.png';
import menu from './menu.png';

import './Navigation.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedIn } = useSelector((state) => state.signInReducer);

  const [selectedTab, setSelectedTab] = useState('learn');

  useEffect(() => {
    if (loggedIn) {
      const intervalId = setInterval(() => {
        dispatch(getUserData());
      }, 10000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, loggedIn]);

  const navClassName = classNames('ace-navigation', loggedIn ? 'ace-navigation__boxShadow' : '');

  const callLogOut = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn && window.location.pathname === '/login') {
      navigate('/');
    }
  }, [navigate, loggedIn]);

  useEffect(() => {
    if (location.pathname.includes('practise')) {
      setSelectedTab('practise')
    }
  }, [location.pathname])

  function onTabChange(newTab) {
    setSelectedTab(newTab)
    switch (newTab) {
      case 'learn':
        navigate('/')
        return
      case 'practise':
        navigate('/practise-class')
        return
      default:
        navigate('/')
    }
  }

  return (
    <>
      <nav className={navClassName}>
        <div className="ace-navigation__tempImageContainer">
          <img src={logo} className="ace-navigation__logoImage" alt="logo" />
          <img src={mobileLogo} className="ace-navigation__logoImageMobile" alt="logo" />
        </div>
        {loggedIn && (
          <div className="ace-navigation__navItemsContainer">
            <div className="ace-navigation__navItemsBoxOne">
              <span
                onClick={() => onTabChange('learn')}
                className={classNames('ace-navigation__navItem', {
                  'ace-navigation__navItem--selected': selectedTab === 'learn'
                })}>
                Learn
              </span>
              <span
                onClick={() => onTabChange('practise')}
                className={classNames('ace-navigation__navItem', {
                  'ace-navigation__navItem--selected': selectedTab === 'practise'
                })}>
                Practice</span>
            </div>
            <div className="ace-navigation__navItemsBoxTwo">
              <img src={leaderboard} alt="leaderboard" />
              <img src={rewardpoints} alt="rewardpoints" />
              <img src={profile} alt="profile" />
            </div>
          </div>
        )}
        {loggedIn && (
          <div>
            <Button
              buttonType="blue"
              className={classNames('ace-loginPage__loginButton', 'ace-loginPage__navLogoutButton')}
              type="button"
              handleClick={() => callLogOut()}
            // disabled={signInLoading}
            // buttonIcon={sendMessageLoader ? "" : sent ? "check1" : "send"}
            // iconDynamicClassName={
            //   sent || sendMessageLoader
            //     ? ""
            //     : "tmx-sendMessageDashboard__icon"
            // }
            // iconSize={sent ? "small" : "ginormous"}
            // spinner={signInLoading}
            >
              Logout
            </Button>
            <img src={menu} className="ace-loginPage__hamburgerMenu" alt="menu" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              class="ace-loginPage__hamburgerMenu">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg> */}
          </div>
        )}
      </nav>
    </>
  );
};

Navigation.propTypes = {};

export default Navigation;
